import { url } from '../App';
import { handleAPIError } from './Utils';

export function getCurrentPoaVersions(type: string) {
  return get(`${url}/poa/${type}/current`);
}

export function getChangeEvents() {
  return get(`${url}/poachangeevents`);
}

export async function get(endpoint: string) {
  try {
    const response = await fetch(endpoint);
    return response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function declineChangeEvent(
  changeEventId: any,
  declineReason: string,
  poaKey: any
) {
  const response = await fetch(`${url}/poachangeevents/decline`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      changeEventId: changeEventId,
      declineReason: declineReason,
      poaKey
    })
  });
  return response.json();
}

export async function createInitialPOA(data: any, fileSelected: any) {
  const formData = new FormData();
  formData.append('file', fileSelected);
  formData.append('poaId', data.poaId);
  formData.append('documentIssueDate', data.issueDate);
  if (data.expireDate) {
    formData.append('documentExpiryDate', data.expireDate);
  }
  if (data.agency) {
    formData.append('agencyCode', data.agencyCode);
  }
  formData.append('attorneysInFact', JSON.stringify(data.AIFs));

  return fetch(`${url}/poa`, {
    method: 'POST',
    body: formData
  });
}

export async function acceptAndUpload(
  currentPOA: any,
  fileSelected: any,
  issueDate: any,
  expireDate: any,
  poaId: string
) {
  const formData = new FormData();
  formData.append('file', fileSelected);
  formData.append('documentIssueDate', issueDate);
  formData.append('documentExpiryDate', expireDate ? expireDate : '');
  formData.append('poaKey', currentPOA.poaKey);
  formData.append('poaId', poaId);
  return fetch(
    `${url}/poachangeevents/approveAndUpload/${currentPOA.changeEventId}`,
    {
      method: 'POST',
      body: formData
    }
  );
}

export async function editSuretyOwnedPOA(
  poaKey: any,
  data: any,
  fileSelected: any
) {
  const formData = new FormData();
  formData.append('file', fileSelected);
  formData.append('documentIssueDate', data.issueDate);
  formData.append('documentExpiryDate', data.expireDate ? data.expireDate : '');
  formData.append('poaId', data.poaId);
  formData.append('attorneysInFact', JSON.stringify(data.AIFs));

  return fetch(`${url}/tokenizepoa/createNewAndUpload/${poaKey}`, {
    method: 'POST',
    body: formData
  });
}

export enum TransStatus {
    pending = "Pending",
    approved = "Surety Approved",
    accepted = "Agency Accepted",
    declinedBySurety = "Declined by Surety",
    declinedByAgency = "Declined by Agency"
}

export enum AppType {
  surety = 'surety',
  agency = 'agency',
  obligee = 'obligee'
}
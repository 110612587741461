import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { Button, Checkbox, TextField } from '@mui/material';
import { AifMaintenanceDetails, AifPOA } from '../services/EmployeeService';
import TrashIcon from '@mui/icons-material/Clear';

export interface AifTableRecord {
  aif: AifMaintenanceDetails;
  setAif: (newAif: AifMaintenanceDetails) => void;
  handleDeleteButton?: (id: number) => void;
  lockPoa: (poa: any) => boolean;
}

export default function AifTableRow(props: AifTableRecord) {
  const classes = useStyles();
  let { aif, setAif, handleDeleteButton, lockPoa } = props;

  const wrapPoa = (p: AifPOA) => {
    let cypressTag = `${p.poaId}-${p.version}-chk`;
    return (
      <TableCell
        key={p.poaKey + p.poaId + p.version}
        className={classes.checkboxCell}
      >
        <Checkbox
          data-cy={cypressTag}
          sx={{ display: 'center', width: '80px' }}
          disabled={lockPoa(p.poaKey)}
          checked={p.isAif}
          onChange={() => {
            const poas = [...aif.poas];
            const p1 = poas.find((p1) => p1.id === p.id);
            if (p1) p1.isAif = !p1.isAif;
            setAif({ ...aif, poas });
          }}
        />
      </TableCell>
    );
  };

  let lockRow = aif.poas.some((p) => p.isAif && lockPoa(p.poaKey));
  let isAIFOnPOAs = aif.poas.some((p) => p.isAif);

  const wrapText = (
    required: boolean,
    textInputWidthSize: 'small' | 'medium' | 'large',
    value: string,
    cypressTag: string,
    onChange: (v: string) => void
  ) => {
    return (
      <TableCell
        className={
          textInputWidthSize === 'small'
            ? classes.smallTableCell
            : textInputWidthSize === 'medium'
            ? classes.tableCell
            : classes.largeTableCell
        }
      >
        <TextField
          data-cy={cypressTag}
          disabled={lockRow || !isAIFOnPOAs}
          required={required}
          onChange={(e) => onChange(e.target.value)}
          size="small"
          fullWidth
          value={value ?? ''}
          className={classes.textField}
          InputProps={{ sx: { fontSize: '12px', fontFamily: 'Spline Sans' } }}
        />
      </TableCell>
    );
  };

  const updateAif = (key: string, value: string) => {
    setAif({
      ...aif,
      [key]: value
    });
  };

  return (
    <TableRow key={aif.id}>
      {wrapText(true, 'medium', aif.firstName, 'first-name-input', (s) =>
        updateAif('firstName', s)
      )}
      {wrapText(false, 'small', aif.middleName, 'middle-name-input', (s) =>
        updateAif('middleName', s)
      )}
      {wrapText(true, 'medium', aif.lastName, 'last-name-input', (s) =>
        updateAif('lastName', s)
      )}
      {wrapText(false, 'small', aif.suffix, 'suffix-input', (s) =>
        updateAif('suffix', s)
      )}
      {wrapText(true, 'large', aif.email, 'email-input', (s) =>
        updateAif('email', s)
      )}

      {aif.poas.map((p) => wrapPoa(p))}

      <TableCell className={classes.smallTableCell}>
        {handleDeleteButton ? (
          <>
            <TrashIcon
              className={classes.trashIcon}
              onClick={() => handleDeleteButton!(aif.id)}
            />
          </>
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles(() => ({
  tableCell: {
    maxWidth: '120px',
    padding: '0 5px'
  },
  smallTableCell: {
    maxWidth: '20px',
    padding: '0 5px'
  },
  largeTableCell: {
    maxWidth: '200px',
    padding: '0 5px'
  },
  checkboxCell: {
    width: '80px',
    paddingTop: 0,
    paddingBottom: 0
  },
  textField: {
    fontFamily: 'Spline Sans',
    fontSize: '12px'
  },
  addedAif: {
    backgroundColor: 'rgb(49, 140, 95, 0.15)'
  },
  trashIcon: {
    color: '#C82027',
    cursor: 'pointer'
  }
}));

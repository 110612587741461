import { Modal, Backdrop, makeStyles } from '@material-ui/core';
import { Box, Divider, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { aifInfo, IChangeEvent } from '../data/interfaces';
import { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import POADropzone from './POADropzone';
import { acceptAndUpload, declineChangeEvent } from '../services/SuretyService';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { joinNames } from '../services/Utils';

interface SuretyProcessChangeProps {
  modalOpen: boolean;
  setModalOpen: any;
  currentPOA: IChangeEvent | null;
  setRefresh: any;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '800px'
  },
  textArea: {
    width: '730px',
    marginBottom: '10px'
  },
  poaTextbox: {
    margin: '10px'
  },
  AIFBox: {
    marginTop: '20px',
    width: '100%'
  },
  poaInfo: {
    marginBottom: '20px'
  },
  arrowIcon: {
    top: '5px',
    position: 'relative',
    paddingLeft: '3px',
    paddingRight: '3px'
  },
  buttonEnabledGreen: {
    color: 'green !important',
    float: 'right'
  },
  buttonEnabledRed: {
    color: 'red !important',
    float: 'right'
  },
  buttonDisabled: {
    color: '#d3d3d3 !important',
    borderColor: '#d3d3d3 !important',
    float: 'right'
  },
  buttonStack: {
    float: 'right'
  }
}));

export default function SuretyProcessChange(props: SuretyProcessChangeProps) {
  const { currentPOA, modalOpen, setModalOpen, setRefresh } = props;

  const classes = useStyles();
  let today = new Date();
  const [step, setStep] = useState<string>('initial');
  const [declineReason, setDeclineReason] = useState<string>('');
  const [issueDate, setIssueDate] = useState<Date | null>(today);
  const [expireDate, setExpireDate] = useState<Date | null>(null);
  const [poaId, setPoaId] = useState(currentPOA?.poaId);
  const [poaPdf, setPoaPdf] = useState<File>();

  const handleDecline = async () => {
    await declineChangeEvent(
      currentPOA?.changeEventId,
      declineReason,
      currentPOA?.poaKey
    );
    handleCloseModal(); //TODO: need some confirmation probably and refresh page?
    setTimeout(() => {
      setRefresh('decline');
    }, 2000);
  };

  const handleApprove = async () => {
    await acceptAndUpload(
      currentPOA,
      poaPdf,
      issueDate,
      expireDate,
      poaId ? poaId : (currentPOA?.poaId as string)
    );
    handleCloseModal(); //TODO: need some confirmation probably and refresh page?
    setTimeout(() => {
      setRefresh('approve');
    }, 2000);
  };

  const renderDeclineScreen = () => {
    return (
      <div className={classes.paper}>
        <h3>Decline POA {currentPOA?.poaId}</h3>
        <TextField
          data-cy="decline-input"
          className={classes.textArea}
          id="outlined-multiline-static"
          label="Comments"
          multiline
          rows={4}
          onChange={(e) => setDeclineReason(e.target.value)}
        />
        <Button
          data-cy="submit-decline-btn"
          className={
            declineReason ? classes.buttonEnabledRed : classes.buttonDisabled
          }
          variant="outlined"
          onClick={() => handleDecline()}
          disabled={!declineReason}
          sx={{ mt: 2 }}
        >
          Decline
        </Button>
      </div>
    );
  };

  let approveButtonDisabled = !poaPdf;

  const renderApproveScreen = () => {
    return (
      <div className={classes.paper}>
        <h3>Approve and Upload Document for POA {currentPOA?.poaId}</h3>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <Grid item xs={4}>
              <DesktopDatePicker
                label="POA Issue Date"
                inputFormat="MM/dd/yyyy"
                disabled={true}
                value={issueDate}
                onChange={(keyboardInputValue) =>
                  setIssueDate(keyboardInputValue)
                }
                renderInput={(params: any) => (
                  <TextField
                    data-cy="issue-date-input"
                    size="normal"
                    variant="standard"
                    className={classes.poaTextbox}
                    required
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <DesktopDatePicker
                disablePast={true}
                label="POA Expire Date"
                inputFormat="MM/dd/yyyy"
                value={expireDate}
                onChange={(keyboardInputValue) =>
                  setExpireDate(keyboardInputValue)
                }
                renderInput={(params: any) => (
                  <TextField
                    data-cy="expire-date-input"
                    className={classes.poaTextbox}
                    {...params}
                    size="normal"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={4}>
            <TextField
              data-cy="poa-id-input"
              id="standard-basic"
              label="POA ID"
              className={classes.poaTextbox}
              required
              onChange={(e) => setPoaId(e.target.value)}
              defaultValue={currentPOA?.poaId}
              size="medium"
              variant="standard"
            />
          </Grid>
        </Grid>
        <POADropzone setPoaPdf={setPoaPdf} />
        <Button
          data-cy="submit-approve-btn"
          className={
            approveButtonDisabled
              ? classes.buttonDisabled
              : classes.buttonEnabledGreen
          }
          variant="outlined"
          onClick={() => handleApprove()}
          sx={{ mt: 2 }}
          disabled={approveButtonDisabled}
        >
          Approve and Upload
        </Button>
      </div>
    );
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setExpireDate(null);
    setStep('initial');
  };

  const renderAIFChanges = () => {
    if (currentPOA?.aifChanges) {
      let changeList = [];
      for (let change of currentPOA.aifChanges) {
        if (change.type === 'update') {
          changeList.push(
            <div data-cy="updated-aif-txt" key={Math.random()}>
              <Typography display="inline" variant="body2">
                UPDATE:{' '}
              </Typography>
              <Typography color="red" display="inline" variant="body2">
                {joinNames(change.oldState)}
              </Typography>
              <ArrowRightAltIcon
                display="inline"
                fontSize="small"
                className={classes.arrowIcon}
              />
              <Typography color="green" display="inline" variant="body2">
                {joinNames(change.currentState)}
              </Typography>
            </div>
          );
        } else if (change.type === 'add') {
          changeList.push(
            <div data-cy="added-aif-txt" key={Math.random()}>
              <Typography display="inline" variant="body2">
                ADD:{' '}
              </Typography>
              <Typography color="green" display="inline" variant="body2">
                {joinNames(change.currentState)}
              </Typography>
            </div>
          );
        } else if (change.type === 'remove') {
          changeList.push(
            <div data-cy="remove-aif-txt" key={Math.random()}>
              <Typography display="inline" variant="body2">
                REMOVE:{' '}
              </Typography>
              <Typography color="red" display="inline" variant="body2">
                {joinNames(change.oldState)}
              </Typography>
            </div>
          );
        }
      }
      return changeList;
    }
  };

  const renderInitialScreen = () => {
    return (
      <div className={classes.paper}>
        <h3>Process POA {currentPOA?.poaId}</h3>
        <Grid container rowSpacing={1} columns={8} className={classes.poaInfo}>
          <Grid item xs={4}>
            <Typography data-cy="txn-id-txt" variant="body2">
              Transaction ID: {currentPOA?.changeEventId}{' '}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography data-cy="status-txt" variant="body2">
              Status: {currentPOA?.status}{' '}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography data-cy="agency-code-txt" variant="body2">
              Agency Code: {currentPOA?.agencyIdentity}{' '}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography data-cy="poa-id-txt" variant="body2">
              Current POA ID: {currentPOA?.poaId}{' '}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography data-cy="version-txt" variant="body2">
              Current Version: {currentPOA?.currentPoaVersion}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Box className={classes.AIFBox}>
          <Typography variant="body1">AIF Changes:</Typography>
          {renderAIFChanges()}
        </Box>
        <Stack direction="row" className={classes.buttonStack} spacing={2}>
          <Button
            data-cy="first-decline-btn"
            className={classes.buttonEnabledRed}
            variant="outlined"
            onClick={() => {
              setDeclineReason('');
              setStep('decline');
            }}
          >
            Decline
          </Button>
          <Button
            data-cy="first-approve-btn"
            variant="outlined"
            className={classes.buttonEnabledGreen}
            onClick={() => setStep('approve')}
            sx={{ mt: 2 }}
          >
            Approve and Upload
          </Button>
        </Stack>
      </div>
    );
  };

  return (
    <Modal
      data-cy="proecss-change-modal"
      className={classes.modal}
      open={modalOpen}
      onClose={() => handleCloseModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      {step === 'decline'
        ? renderDeclineScreen()
        : step === 'approve'
        ? renderApproveScreen()
        : renderInitialScreen()}
    </Modal>
  );
}

import { url } from '../App';
import { handleAPIError } from './Utils';
import { RequestQueryBuilder, CondOperator } from "@nestjsx/crud-request";

export interface PoaVersion {
  id: number;
  poaId: string;
  suretyCompany: string;
  status: string;
  poaKey: string;
  version: number;
  legalDocumentId: string;
  agencyCode?: string;
}

export const downloadFile = async (legalDocumentId: any, poaId: string) => {
  const file = await fetch(
    `${url}/poaversion/download/${legalDocumentId}`
  );
  const blob = await file.blob();
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = poaId + '.pdf';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export async function getPoaVersions(agencyCodeQuery: string, suretyQuery: string) {
  let query = `${url}/poaversion?filter=status||$eq||Current&sort=createdAt,ASC`;
  if (agencyCodeQuery !== '') {
    query += `&filter=agencyCode||$starts||${agencyCodeQuery}`;
  }
  if (suretyQuery !== '') {
    query += `&filter=suretyCompany||$eq||${suretyQuery}`;
  }
  const response = await fetch(query);
  return response.json();
}

export async function getPoaVersionsByKey(poaKey: any) {
  try {
    const queryString = RequestQueryBuilder.create({
    search: { poaKey: poaKey },
    sort: [{ field: "createdAt", order: "DESC" }],
    resetCache: true
  }).query();
    const response = await fetch(`${url}/poaversion?${queryString}`);
    return response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function getAllSuretyPoaVersions() {
  try {
    let query = `${url}/poaversion?filter=agencyCode||$isnull&sort=status,ASC`; //show current first
    const response = await fetch(query);
    return response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }}
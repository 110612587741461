import { url } from '../App';
import { handleAPIError } from './Utils';
import { RequestQueryBuilder } from "@nestjsx/crud-request";

export async function getAgencyChangeEvents() {
  try {
    const response = await fetch(`${url}/poachangeevents`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function agencyDeclineChangeEvent(
  changeEventId,
  declineReason,
  poaKey
) {
  try {
    const response = await fetch(`${url}/poachangeevents/decline`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        changeEventId: changeEventId,
        declineReason: declineReason,
        poaKey: poaKey
      })
    });
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function agencyAcceptChangeEvent(changeEventId, poaKey) {
  try {
    const response = await fetch(
      `${url}/poachangeevents/acceptRevision`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          changeEventId: changeEventId,
          poaKey: poaKey
        })
      }
    );
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function getAgencies() {
  try {
    const response = await fetch(`${url}/agency`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function createPoaChangeEvent(body) {
  try {
    const response = await fetch(`${url}/poachangeevents`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function getChangeEventsByPoaKey(poaKey) {
  try {
    const queryString = RequestQueryBuilder.create({
    search: { poaKey: poaKey },
    sort: [{ field: "updatedAt", order: "DESC" }],
    resetCache: true
  }).query();
    const response = await fetch(`${url}/poachangeevents?${queryString}`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function getAIFs() {
  try {
    const response = await fetch(`${url}/aifs`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export async function getAIFByHash(hash) {
  try {
    const response = await fetch(`${url}/aifs/${hash}`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

export const downloadFile = async (legalDocumentId, poaId) => {
  const file = await fetch(
    `${url}/poaversion/download/${legalDocumentId}`
  );
  const blob = await file.blob();
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = poaId + '.pdf';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getFireflyOrgs = async () => {
  try {
    const response = await fetch(`${url}/firefly/orgs`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
};

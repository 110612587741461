import { TextField } from '@material-ui/core';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { obligeeCheck, obligeeGetFFMembers } from '../services/ObligeeService';
import { IBondData, IFFMember, IOrg } from '../data/interfaces';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'right',
    float: 'right'
  },
  header: {
    paddingBottom: '20px'
  },
  grid: {
    paddingTop: '20px'
  },
  box: {
    height: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  alert: {
    marginTop: '20px'
  },
  suretySelect: {
    width: '175px'
  },
  suretyLabel: {
    top: '20px !important'
  }
}));

export default function ObligeeVerification() {
  const defaultValues = {
    agencyCode: '',
    poaId: '',
    surety: '',
    firstName: '',
    middleName: null,
    lastName: '',
    suffix: null,
    email: '',
    certificationDate: new Date().toISOString()
  };
  const classes = useStyles();
  const [type, setType] = useState('agency');
  const [submitted, setSubmitted] = useState(false);
  const [verified, setVerified] = useState<string | null>(null);

  const [formValues, setFormValues] = useState<IBondData>(defaultValues);
  const [suretyCompany, setSuretyCompany] = useState('');
  const [FFMembers, setFFMembers] = useState<IFFMember[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await obligeeGetFFMembers();
      setFFMembers(res);
    };
    fetchData();
  }, []);

  const handleSuretyChange = (event: any) => {
    setSuretyCompany(event.target.value);
  };

  const suretySelection: any = [];
  FFMembers.map((c: IOrg) => {
    if (
      // if its a surety
      c.name === 'org_0' || //  TODO: first 2 conditions are temporary until FF updates
      c.name === 'org_3' ||
      c.name.includes('s1') || // test env names
      c.name.includes('s2') ||
      c.name === 'surety1' || // dev env names
      c.name === 'surety2'
    ) {
      return suretySelection.push(
        <MenuItem key={c.identity} value={c.identity}>
          {c.name}
        </MenuItem>
      );
    }
  });

  const isFormValid = () => {
    if (
      (type === 'agency' ? formValues.agencyCode : true) &&
      formValues.poaId &&
      suretyCompany &&
      formValues.firstName &&
      formValues.lastName &&
      formValues.email &&
      formValues.certificationDate
    ) {
      return true;
    }
    return false;
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setType(newAlignment);
    setSubmitted(false);
    setFormValues({ ...formValues, agencyCode: '' });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSubmitted(false);
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    if (formValues.middleName === '') {
      formValues.middleName = null;
    }
    if (formValues.suffix === '') {
      formValues.suffix = null;
    }
    await obligeeCheck(formValues, suretyCompany).then(
      async (response: any) => {
        console.log(response);
        if (response.ok || response.status) {
          if (response.isValid) {
            setVerified('valid');
          } else {
            setVerified('invalid');
          }
        } else {
          setVerified('invalid');
        }
      }
    );
    setSubmitted(true);
  };

  return (
    <Box bgcolor="white" p="20px" width="700px" border={1} borderColor="gray">
      <Typography variant="h6" className={classes.header}>
        Power of Attorney Validation
        <ToggleButtonGroup
          color="primary"
          value={type}
          exclusive
          onChange={handleToggleChange}
          size="small"
          className={classes.buttonGroup}
        >
          <ToggleButton value="agency">Agency</ToggleButton>
          <ToggleButton value="non-agency">Non-Agency</ToggleButton>
        </ToggleButtonGroup>
      </Typography>
      <Divider />
      <FormControl>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={4}>
            <InputLabel
              id="demo-simple-select-standard-label"
              className={classes.suretyLabel}
            >
              Surety Company
            </InputLabel>
            <Select
              data-cy="surety-input"
              labelId="demo-simple-select-standard-label"
              id="suretyCompanySelection"
              value={suretyCompany}
              label="Surety Company"
              onChange={handleSuretyChange}
              className={classes.suretySelect}
            >
              {suretySelection}
            </Select>
          </Grid>
          <Grid item xs={3} hidden={type === 'non-agency'}>
            <TextField
              data-cy="agency-code-input"
              name="agencyCode"
              label="Agency Code"
              value={formValues.agencyCode}
              variant="outlined"
              required={type === 'agency'}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              data-cy="poa-id-input"
              name="poaId"
              label="POA ID"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={4}>
            <TextField
              data-cy="first-name-input"
              name="firstName"
              label="First Name"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              data-cy="middle-name-input"
              name="middleName"
              label="Middle"
              variant="outlined"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              data-cy="last-name-input"
              name="lastName"
              label="Last Name"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              data-cy="suffix-input"
              name="suffix"
              label="Suffix"
              variant="outlined"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              data-cy="email-input"
              name="email"
              label="Email"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DesktopDatePicker
                label="Certification Date"
                inputFormat="MM/dd/yyyy"
                value={formValues.certificationDate}
                onChange={(e) => {
                  setSubmitted(false);
                  if (!!e) {
                    setFormValues({
                      ...formValues,
                      ['certificationDate']: e
                    });
                  }
                }}
                renderInput={(params: any) => (
                  <TextField
                    data-cy="cert-date-input"
                    name="certificationDate"
                    label="Certification Date"
                    variant="outlined"
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Box width="100%" />
        </Grid>
        <Box component="span" className={classes.box}>
          <Button
            data-cy="submit-btn"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            Submit
          </Button>
        </Box>
      </FormControl>
      {submitted && verified === 'valid' ? (
        <Alert data-cy="valid-txt" className={classes.alert} severity="success">
          Successful validation! We found a complete POA matching the
          information above.
        </Alert>
      ) : submitted && (verified === 'invalid' || !verified) ? (
        <Alert
          data-cy="invalid-txt"
          className={classes.alert}
          severity="warning"
        >
          Unable to validate based on the information provided. Please verify
          the information was entered correctly. If so, please contact your
          Surety Underwriter.
        </Alert>
      ) : null}
    </Box>
  );
}

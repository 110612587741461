import { forwardRef, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Box from '@mui/material/Box';
import { Icons } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles, TextField } from '@material-ui/core';
import { Alert, DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import {
  createInitialPOA,
  editSuretyOwnedPOA
} from '../services/SuretyService';
import LoadingButton from '@mui/lab/LoadingButton';
import POADropzone from './POADropzone';
import { aifInfo, IAgency, ISuretyPOAData } from '../data/interfaces';
import { getAgencies } from '../services/AgencyService';
import { validateEmail } from '../services/Utils';
import { Button } from '@mui/material';

interface UploadNewPOAProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPOAUploadStatus: React.Dispatch<React.SetStateAction<string>>;
  POAUploadStatus: string;
  setRefresh: React.Dispatch<React.SetStateAction<string>>;
  suretyPOAOnly: boolean;
  editMode?: boolean;
  prepopulatedData?: ISuretyPOAData | null;
}

interface AIF {
  firstName: string;
  middleName: string | null;
  lastName: string;
  suffix: string | null;
  email: string;
  employeeId?: string | null;
}

interface ITableDataAfterRowUpdate extends aifInfo {
  tableData: { id: number; editing?: string };
}

const useStyles = makeStyles({
  uploadButton: {
    float: 'right',
    borderColor: '#D1855B',
    color: '#D1855B !important'
  },
  poaTextbox: {
    margin: '10px'
  },
  autocomplete: {
    marginBottom: '20px',
    marginLeft: '10px'
  },
  submitButtonDisabled: {
    float: 'right',
    borderColor: '#d3d3d3 !important',
    color: '#d3d3d3 !important'
  }
});

export default function UploadNewPOA(props: UploadNewPOAProps) {
  const [allAgencies, setAllAgencies] = useState<any[]>([]);

  useEffect(() => {
    setPOAUploadStatus('');
    const fetchData = async () => {
      let res = await getAgencies();
      console.log(res);
      setAllAgencies(res);
    };
    fetchData();
  }, []);

  const { pathname } = useLocation();

  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const {
    prepopulatedData,
    setModalOpen,
    setPOAUploadStatus,
    POAUploadStatus,
    setRefresh,
    editMode = false,
    suretyPOAOnly = pathname === '/suretyownedpoas'
  } = props;
  let today = new Date();
  const classes = useStyles();
  const [data, setData] = useState<aifInfo[]>(
    prepopulatedData?.attorneysInFact ?? []
  ); // prepopulate if editing
  const [issueDate, setIssueDate] = useState<Date | null>(today);
  const [expireDate, setExpireDate] = useState<Date | null>(null);
  const [agencyCode, setAgencyCode] = useState<string | null>(null);
  const [agency, setAgency] = useState<string | null>(null);
  const [poaId, setPoaId] = useState(prepopulatedData?.poaId);
  const [poaPdf, setPoaPdf] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false); // loading after POA upload
  const [agencyCodes, setAgencyCodes] = useState<string[]>([]);
  const [agencyIdentity, setAgencyIdentity] = useState<string | null>(null);

  const columns = [
    {
      title: 'First*',
      field: 'firstName',
      require,
      validate: (rowData: any) => Boolean(rowData.firstName)
    },
    { title: 'Middle', field: 'middleName' },
    {
      title: 'Last*',
      field: 'lastName',
      require,
      validate: (rowData: any) => Boolean(rowData.lastName)
    },
    { title: 'Suffix', field: 'suffix' },
    {
      title: 'Email*',
      field: 'email',
      require,
      validate: (rowData: any) => validateEmail(rowData.email)
    }
  ];

  // when user selects an agency, grab all agency codes associated with that agency and set the agency identity
  async function pickAgency(value: string | null) {
    setAgencyCode(null);
    setAgencyCodes([]);
    setAgency(value);
    const agency = allAgencies.find((a) => a.name === value);
    if (agency !== undefined) {
      setAgencyIdentity(agency.identity);
      setAgencyCodes(agency.agencyCodes);
    }
  }

  async function handleSubmit() {
    setPOAUploadStatus('');
    setLoading(true);

    // remove dataTable property added by table
    const AIFs = (data as ITableDataAfterRowUpdate[]).map((d): AIF => {
      return {
        firstName: d.firstName,
        middleName: d.middleName || null,
        lastName: d.lastName,
        suffix: d.suffix || null,
        email: d.email,
        employeeId: null
      };
    });
    let body = {
      issueDate,
      expireDate,
      poaId,
      agency: agencyIdentity,
      agencyCode: agencyCode,
      AIFs
    };
    if (!editMode) {
      await createInitialPOA(body, poaPdf)
        .then(async (response) => {
          if (response.ok) {
            setPOAUploadStatus('success');
            setModalOpen(false);
            setRefresh('initial upload');
          } else {
            setPOAUploadStatus('error');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await editSuretyOwnedPOA(prepopulatedData?.poaKey, body, poaPdf)
        .then(async (response) => {
          if (response.ok) {
            setPOAUploadStatus('success');
            setRefresh('upload edited');
            setModalOpen(false);
          } else {
            setPOAUploadStatus('error');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function renderAlert() {
    if (POAUploadStatus === 'error') {
      return (
        <Alert severity="error">
          POA creation has failed! Please try again.
        </Alert>
      );
      //  commented since we are closing the modal upon success - and showing error banner on error
      // } else if (POAUploadStatus === 'success') {
      //   return <Alert severity="success">Your POA has been created!</Alert>;
    }
  }

  let submitDisabled = data.length === 0 || !poaPdf || !poaId || loading;

  return (
    <>
      {renderAlert()}
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DesktopDatePicker
          label="POA Issue Date"
          inputFormat="MM/dd/yyyy"
          disabled={true}
          value={issueDate}
          onChange={(keyboardInputValue) => setIssueDate(keyboardInputValue)}
          renderInput={(params: any) => (
            <TextField
              data-cy="issue-date-input"
              className={classes.poaTextbox}
              required
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          data-cy="expire-date-input"
          disablePast={true}
          label="POA Expire Date"
          inputFormat="MM/dd/yyyy"
          value={expireDate}
          onChange={(keyboardInputValue) => setExpireDate(keyboardInputValue)}
          renderInput={(params: any) => (
            <TextField className={classes.poaTextbox} {...params} />
          )}
        />
      </LocalizationProvider>
      <TextField
        data-cy="poa-id-input"
        id="standard-basic"
        label="POA ID"
        className={classes.poaTextbox}
        defaultValue={prepopulatedData?.poaId}
        required
        onChange={(e) => setPoaId(e.target.value)}
      />
      <Box sx={{ flexDirection: 'row', display: 'flex' }}>
        {!suretyPOAOnly && (
          <Autocomplete
            data-cy="agency-input"
            disablePortal
            options={allAgencies.map((agency) => agency.name)}
            sx={{ width: 200 }}
            value={agency}
            renderInput={(params) => <TextField {...params} label="Agency" />}
            onChange={(event, value) => pickAgency(value)}
            className={classes.autocomplete}
          />
        )}
        {agency && (
          <Autocomplete
            data-cy="agency-code-input"
            disablePortal
            options={agencyCodes}
            sx={{ width: 200 }}
            value={agencyCode}
            renderInput={(params) => (
              <TextField {...params} label="Agency Code" />
            )}
            onChange={(event, value) => setAgencyCode(value)}
            className={classes.autocomplete}
          />
        )}
      </Box>
      <MaterialTable
        columns={columns}
        data={data}
        title="Add AIFs"
        editable={{
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                setData([...data, newData]);
                resolve();
              }, 500);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = (oldData as ITableDataAfterRowUpdate).tableData
                  .id;
                if (index !== undefined) {
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                }
                resolve();
              }, 500);
            }),
          onRowDelete: (selectedRow) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                const updatedData = [...data];
                updatedData.splice(
                  (selectedRow as ITableDataAfterRowUpdate).tableData.id,
                  1
                );
                setData(updatedData);
                resolve();
              }, 500);
            })
        }}
        options={{
          filtering: false,
          paging: true,
          pageSize: 5,
          pageSizeOptions: [],
          paginationType: 'normal',
          showFirstLastPageButtons: true,
          paginationPosition: 'bottom',
          search: false,
          toolbarButtonAlignment: 'left',
          padding: 'dense',
          sorting: false
        }}
        icons={tableIcons}
      />
      <POADropzone setPoaPdf={setPoaPdf} />
      <Button
        data-cy="cancel-btn"
        variant="outlined"
        sx={{
          borderColor: '#D1855B',
          textTransform: 'none',
          color: '#D1855B !important'
        }}
        onClick={() => setModalOpen(false)}
      >
        Cancel
      </Button>
      <LoadingButton
        data-cy="submit-btn"
        onClick={() => handleSubmit()}
        loading={loading}
        variant="outlined"
        disabled={submitDisabled}
        className={
          submitDisabled ? classes.submitButtonDisabled : classes.uploadButton
        }
        type="submit"
        sx={{
          textTransform: 'none'
        }}
      >
        Upload
      </LoadingButton>
    </>
  );
}

import Container from '@mui/material/Container';
import PageHeader from '../components/PageHeader';
import { useState, forwardRef, useEffect, useContext } from 'react';
import { Modal, Typography, Tooltip } from '@material-ui/core';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { Icons } from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Backdrop from '@material-ui/core/Backdrop';
import {
  IAgencyTransactionData,
  IOrg,
  ISuretyCompany,
  ITransactionData
} from '../data/interfaces';
import { TransStatus } from '../data/enums';
import { Box, TextField } from '@mui/material';
import {
  agencyAcceptChangeEvent,
  agencyDeclineChangeEvent,
  getAgencyChangeEvents,
  getFireflyOrgs
} from '../services/AgencyService';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { openAuditHistory } from '../services/Utils';
import { ArrowDownward } from '@material-ui/icons';
import { AppContext } from '../contexts/AppContext';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '500px',
    fontSize: '12px !important'
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '160px'
  },
  textArea: {
    width: '430px',
    marginBottom: '10px'
  },
  poaLink: {
    fontSize: '12px !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#D1855B'
  },
  buttonEnabledGreen: {
    color: 'green !important',
    float: 'right'
    // marginTop: '20px'
  },
  buttonEnabledRed: {
    color: 'red !important',
    float: 'right'
    // marginTop: '20px'
  },
  buttonDisabled: {
    color: '#d3d3d3 !important',
    borderColor: '#d3d3d3 !important',
    float: 'right'
    // marginTop: '20px'
  }
}));

export default function AgencyProcessPOAs() {
  const tableIcons: Icons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    ))
  };

  const sortOrder = [
    'Surety Approved',
    'Pending',
    'Declined by Surety',
    'Declined by Agency',
    'Agency Accepted'
  ];

  const [currentPOA, setCurrentPOA] = useState<IAgencyTransactionData | null>(
    null
  );
  const [data, setData] = useState<ITransactionData[]>([]);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [refresh, setRefresh] = useState('');
  const [orgs, setOrgs] = useState<IOrg[]>([]);
  const [lastUpdate, setLastUpdate] = useState(0);
  const { lastEvent } = useContext(AppContext);

  useEffect(() => {
    const fetchOrgs = async () => {
      const result = await getFireflyOrgs();
      setOrgs(result);
    };
    fetchOrgs();
  }, []);

  useEffect(() => {
    if (lastEvent && lastEvent.event === 'poa-updated') {
      setLastUpdate(new Date().getTime());
    }
  }, [lastEvent]);

  useEffect(() => {
    const fetchData = async () => {
      let result = await getAgencyChangeEvents();
      result = result.map((d: any) => {
        return {
          ...d,
          poaKeyLink: d.poaKey,
          updatedAt: new Date(d.updatedAt).toLocaleString()
        };
      });
      result.sort((a: ITransactionData, b: ITransactionData) => {
        return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status);
      });
      setData(
        result.map((r: ITransactionData) => {
          const suretyFound: any = orgs.find(
            (s: ISuretyCompany) => s.identity === r.suretyIdentity
          );
          return {
            ...r,
            suretyIdentity: suretyFound
              ? getSuretyNameByIdentity(r.suretyIdentity)
              : r.suretyIdentity
          };
        })
      );
    };
    fetchData().catch(console.error);
  }, [refresh, orgs, lastUpdate]);

  const classes = useStyles();

  const handleAccept = async (changeEventId: string, poaKey: string) => {
    await agencyAcceptChangeEvent(changeEventId, poaKey);
    setTimeout(() => {
      setRefresh('accept');
    }, 2000);
  };

  const handleDecline = async () => {
    await agencyDeclineChangeEvent(
      currentPOA?.changeEventId,
      declineReason,
      currentPOA?.poaKey
    );
    setDeclineModalOpen(false);
    setTimeout(() => {
      setRefresh('decline');
    }, 2000);
  };

  const handleFirstDeclineStep = (changeEventId: string, poaKey: string) => {
    setDeclineReason('');
    setCurrentPOA({ changeEventId: changeEventId, poaKey: poaKey });
    setDeclineModalOpen(true);
  };

  const renderDeclineScreen = () => {
    return (
      <Modal
        className={classes.modal}
        open={declineModalOpen}
        onClose={() => setDeclineModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <div className={classes.paper}>
          <h3>Decline</h3>
          <TextField
            data-cy="decline-reason-input"
            className={classes.textArea}
            id="outlined-multiline-static"
            label="Comments"
            multiline
            rows={4}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
          <Button
            data-cy="submit-decline-btn"
            className={
              declineReason ? classes.buttonEnabledRed : classes.buttonDisabled
            }
            variant="outlined"
            onClick={() => handleDecline()}
            disabled={!declineReason}
            sx={{ mt: 2 }}
          >
            Decline
          </Button>
        </div>
      </Modal>
    );
  };

  const getSuretyNameByIdentity = (identity: string) => {
    for (let org of orgs) {
      if (org.identity === identity) {
        return org.name;
      }
    }
    return null;
  };

  const columns = [
    {
      title: 'Updated At',
      field: 'updatedAt',
      sorting: true
    },
    { title: 'POA ID', field: 'poaId' },
    {
      title: 'POA Key',
      field: 'poaKeyLink',
      render: (rowData: ITransactionData) => {
        return (
          <Typography
            data-cy="audit-history-link"
            className={classes.poaLink}
            onClick={() => openAuditHistory(rowData.poaKey)}
          >
            {rowData.poaKey}
          </Typography>
        );
      }
    },
    {
      title: 'Surety Parent',
      field: 'suretyIdentity',
      sorting: false
    },
    { title: 'Agency Code', field: 'agencyCode' },
    {
      title: 'Version',
      field: 'currentPoaVersion',
      render: (rowData: ITransactionData) => {
        return (
          <Typography variant={'body2'} data-cy="version-txt">
            {rowData.status === TransStatus.approved ||
            rowData.status === TransStatus.accepted
              ? rowData.currentPoaVersion
              : 'N/A'}
          </Typography>
        );
      }
    },
    {
      title: 'Transaction ID',
      field: 'changeEventId',
      render: (rowData: ITransactionData) => {
        return (
          <Tooltip title={rowData.changeEventId}>
            <Typography
              data-cy="change-event-id-txt"
              variant={'body2'}
              onClick={() => {
                navigator.clipboard.writeText(rowData.changeEventId);
              }}
            >
              {rowData.changeEventId.substring(0, 8) + '...'}
              <CopyIcon
                sx={{
                  fontSize: '0.85rem !important',
                  cursor: 'pointer',
                  color: '#d1855b'
                }}
              ></CopyIcon>
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      title: 'Status',
      field: 'status',
      filtering: true,
      sorting: false,
      render: (rowData: ITransactionData) => (
        <div data-cy="status-txt">
          {(rowData.status === TransStatus.declinedByAgency ||
            rowData.status === TransStatus.declinedBySurety) &&
          rowData.declineReason ? (
            <Tooltip title={rowData.declineReason}>
              <div
                style={{
                  color: 'red'
                }}
              >
                {rowData.status}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                color:
                  rowData.status === TransStatus.approved ||
                  rowData.status === TransStatus.accepted
                    ? 'green'
                    : rowData.status === TransStatus.pending
                    ? 'orange'
                    : 'red'
              }}
            >
              {rowData.status}
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Actions',
      filtering: false,
      render: (rowData: ITransactionData) => {
        if (rowData.status === TransStatus.approved) {
          return (
            <Box
              component="span"
              m={1}
              className={classes.box}
              data-cy="action-col"
            >
              <Button
                data-cy="inline-accept-btn"
                size="small"
                variant="outlined"
                sx={{ color: 'green !important' }}
                onClick={() =>
                  handleAccept(rowData.changeEventId, rowData.poaKey)
                }
              >
                Accept
              </Button>
              <Button
                data-cy="inline-decline-btn"
                size="small"
                variant="outlined"
                sx={{ color: 'red !important' }}
                onClick={() =>
                  handleFirstDeclineStep(rowData.changeEventId, rowData.poaKey)
                }
              >
                Decline
              </Button>
            </Box>
          );
        }
        return null;
      }
    }
  ];

  return (
    <Container maxWidth="xl">
      <PageHeader
        header="Process POAs"
        subtitle="Accept or decline powers of attorney documents."
      />
      <MaterialTable
        columns={columns}
        style={{ fontSize: '13px', fontFamily: 'Spline Sans' }}
        data={data}
        options={{
          thirdSortClick: false,
          filtering: true,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25],
          showTitle: false,
          paginationType: 'normal',
          showFirstLastPageButtons: true,
          paginationPosition: 'bottom',
          search: false,
          toolbar: false,
          toolbarButtonAlignment: 'left',
          padding: 'dense',
          rowStyle: {
            overflowWrap: 'normal'
          }
        }}
        icons={tableIcons}
      />
      {declineModalOpen && renderDeclineScreen()}
    </Container>
  );
}

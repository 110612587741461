import { useState, forwardRef, useEffect } from 'react';
import Container from '@mui/material/Container';
import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import MaterialTable from 'material-table';
import { Icons } from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import UploadNewPOA from '../components/UploadNewPOA';
import Backdrop from '@material-ui/core/Backdrop';
import { aifInfo, ISuretyPOAData } from '../data/interfaces';
import { getAllSuretyPoaVersions } from '../services/PoaVersionService';
import { SuretyUploadHeader } from '../components/SuretyUploadHeader';
import { Typography } from '@mui/material';
import { openAuditHistory } from '../services/Utils';
import { ArrowDownward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  poaLink: {
    fontSize: '13px !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#D1855B'
  }
}));

export default function SuretyOwnedPOAs() {
  const tableIcons: Icons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    ))
  };

  const [editPOAModalOpen, setEditPOAModalOpen] = useState(false);
  const [currentPOA, setCurrentPOA] = useState<ISuretyPOAData | null>(null);
  const [POAUploadStatus, setPOAUploadStatus] = useState('');
  const [refresh, setRefresh] = useState('');

  const handleEditClick = (
    poaId: string,
    poaKey: string,
    attorneysInFact?: aifInfo[]
  ) => {
    setCurrentPOA({ poaId, poaKey, attorneysInFact });
    setEditPOAModalOpen(true);
  };

  const [data, setData] = useState<ISuretyPOAData[]>([]);
  const fetchData = async () => {
    let result = await getAllSuretyPoaVersions();
    result = result.map((d: any) => {
      return {
        ...d,
        updatedAt: new Date(d.updatedAt).toLocaleString(),
        poaKey: d.poaKey
      };
    });
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const classes = useStyles();

  const columns = [
    {
      title: 'Updated At',
      field: 'updatedAt'
    },
    {
      title: 'POA ID',
      field: 'poaId',
      width: '100px',
      headerStyle: { width: '100px' }
    },
    {
      title: 'POA Key',
      field: 'poaKey',
      width: '100px',
      headerStyle: { width: '100px' },
      render: (rowData: ISuretyPOAData) => {
        return (
          <Typography
            data-cy="audit-history-link"
            className={classes.poaLink}
            onClick={() => openAuditHistory(rowData.poaKey)}
          >
            {rowData.poaKey}
          </Typography>
        );
      }
    },
    {
      title: 'Status',
      field: 'status',
      width: '80px',
      headerStyle: { width: '80px' }
    },
    {
      title: 'Version',
      field: 'version',
      width: '20px',
      headerStyle: { width: '20px' }
    },
    {
      title: 'Actions',
      headerStyle: { width: '300px' },
      filtering: false,
      render: (rowData: ISuretyPOAData) => {
        return rowData.status === 'Current' ? (
          <>
            <Button
              data-cy="edit-inline-btn"
              size="small"
              variant="outlined"
              onClick={() =>
                handleEditClick(
                  rowData.poaId,
                  rowData.poaKey,
                  rowData.attorneysInFact
                )
              }
            >
              Edit
            </Button>
          </>
        ) : (
          <></>
        );
      }
    }
  ];

  function renderEditModal() {
    return (
      <Modal
        className={classes.modal}
        open={editPOAModalOpen}
        onClose={() => setEditPOAModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={editPOAModalOpen}>
          <div className={classes.paper}>
            <h2>Upload a New POA</h2>
            <UploadNewPOA
              setModalOpen={setEditPOAModalOpen}
              setPOAUploadStatus={setPOAUploadStatus}
              POAUploadStatus={POAUploadStatus}
              suretyPOAOnly={true}
              editMode={true}
              prepopulatedData={currentPOA}
              setRefresh={setRefresh}
            />
          </div>
        </Fade>
      </Modal>
    );
  }

  return (
    <Container maxWidth="xl">
      <SuretyUploadHeader
        header="Surety-owned POAs"
        subtitle="Upload and track surety-owned powers of attorney documents."
        setRefresh={setRefresh}
        suretyPOAOnly={true}
      />
      {renderEditModal()}

      <MaterialTable
        columns={columns}
        data={data}
        options={{
          filtering: true,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25],
          showTitle: false,
          sorting: true,
          paginationType: 'stepped',
          showFirstLastPageButtons: true,
          paginationPosition: 'bottom',
          search: false,
          toolbar: false,
          toolbarButtonAlignment: 'left',
          padding: 'dense',
          rowStyle: {
            overflowWrap: 'normal'
          }
        }}
        icons={tableIcons}
      />
    </Container>
  );
}

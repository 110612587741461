import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PageHeaderProps {
  header: string;
  subtitle: string;
}

export default function PageHeader(props: PageHeaderProps) {
  const { header, subtitle } = props;
  return (
    <Box sx={{ width: '100%', fontFamily: 'Spline Sans' }}>
      <Typography
        variant="h4"
        align="left"
        fontWeight={700}
        fontFamily={'Spline Sans'}
        gutterBottom
        data-cy="header-txt"
      >
        {header}
      </Typography>
      <Typography
        data-cy="subtitle-txt"
        sx={{ paddingBottom: '16px' }}
        variant="subtitle2"
        gutterBottom
        fontFamily={'Spline Sans'}
        align="left"
      >
        {subtitle}
      </Typography>
    </Box>
  );
}

import { useState } from 'react';
import PageHeader from '../components/PageHeader';
import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import UploadNewPOA from '../components/UploadNewPOA';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  uploadHeader: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between'
  },
  uploadButton: {
    minWidth: '200px'
  },
  success: {
    color: 'green'
  },
  pending: {},
  failure: {}
}));

interface Props {
  header: string;
  subtitle: string;
  suretyPOAOnly: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<string>>;
}

export const SuretyUploadHeader: React.FC<Props> = ({
  header,
  subtitle,
  suretyPOAOnly,
  setRefresh
}) => {
  const [open, setOpen] = useState(false); // modal open
  const [POAUploadStatus, setPOAUploadStatus] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();
  function renderNewPOAModal() {
    return (
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>Upload a New POA</h2>
            <UploadNewPOA
              setModalOpen={setOpen}
              setPOAUploadStatus={setPOAUploadStatus}
              POAUploadStatus={POAUploadStatus}
              suretyPOAOnly={suretyPOAOnly}
              setRefresh={setRefresh}
            />
          </div>
        </Fade>
      </Modal>
    );
  }

  return (
    <Box className={classes.uploadHeader}>
      <PageHeader header={header} subtitle={subtitle} />
      <Button
        className={classes.uploadButton}
        onClick={handleOpen}
        data-cy="upload-new-poa-btn"
      >
        Upload a new POA
      </Button>
      {open ? renderNewPOAModal() : null}
    </Box>
  );
};

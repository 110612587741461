import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core';

interface POADropzoneProps {
  setPoaPdf: any;
}

const useStyles = makeStyles({
  dropzone: {
    'margin-top': '20px',
    'margin-bottom': '20px',
    'min-height': '100px'
  },
  dropzoneText: {
    'font-size': 'small'
  },
  dropzoneIcon: {
    width: '25px',
    height: '25px'
  }
});

export default function POADropzone(props: POADropzoneProps) {
  const classes = useStyles();
  const { setPoaPdf } = props;
  return (
    <DropzoneArea
      data-cy="dropzone-input"
      maxFileSize={10000000}
      classes={{ text: classes['dropzoneText'], icon: classes['dropzoneIcon'] }}
      acceptedFiles={['.pdf']}
      filesLimit={1}
      dropzoneText={'Upload PDF'}
      onChange={(files) => setPoaPdf(files[0])}
      dropzoneClass={classes.dropzone}
      showPreviews={false}
      showFileNames={true}
      useChipsForPreview={true}
    />
  );
}

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AifTableRow from './AifTableRow';
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  Aif,
  AifMaintenance,
  AifMaintenanceDetails,
  createNewAif,
  getAifByHash
} from '../services/EmployeeService';
import LockIcon from '@mui/icons-material/Lock';
import isEqual from 'lodash/isEqual';
import { hashAif, openAuditHistory } from '../services/Utils';
import {
  createPoaChangeEvent,
  getFireflyOrgs
} from '../services/AgencyService';
import { IChangeEvent, IOrg } from '../data/interfaces';
import { PoaVersion } from '../services/PoaVersionService';

const useStyles = makeStyles({
  tableContainer: {
    overflowX: 'auto',
    fontFamily: 'Spline Sans !important',
    backgroundColor: '#fff',
    width: '98% !important',
    maxHeight: '500px'
  },
  poaText: {
    fontSize: '12px !important',
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '4px !important'
  },
  poaLink: {
    fontSize: '12px !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#D1855B',
    textAlign: 'center'
  },
  suretyName: {
    fontSize: '14px !important',
    textAlign: 'center'
  }
});

interface AifMaintenanceTableProps {
  data: AifMaintenance[];
  setData: React.Dispatch<React.SetStateAction<AifMaintenance[]>>;
  poaVersions: PoaVersion[];
  changeEvents: IChangeEvent[];
  setRefresh: React.Dispatch<React.SetStateAction<string>>;
}

export default function AgencyManageAIFsTable(props: AifMaintenanceTableProps) {
  const { data, setData, poaVersions, changeEvents, setRefresh } = props;
  const classes = useStyles();
  const [aifs, setAifs] = useState<AifMaintenance[]>([]);
  const [submittingChanges, setSubmittingChanges] = useState(false);
  const [orgs, setOrgs] = useState<IOrg[]>([]);

  useEffect(() => {
    const fetchOrgs = async () => {
      const res = await getFireflyOrgs();
      setOrgs(res);
    };
    fetchOrgs();
  }, []);

  useEffect(() => {
    setAifs(data);
  }, [data]);

  if (!aifs) {
    return <>Loading ... </>;
  }

  if (aifs && aifs.length === 0) {
    return <>No AIFs have been added.</>;
  }

  const getSuretyNameByIdentity = (identity: string) => {
    for (let org of orgs) {
      if (org.identity === identity) {
        return org.name;
      }
    }
    return null;
  };

  const lockPoa = (poaKey: string) => {
    const nonFinalStates = ['Pending', 'Declined by Agency', 'Surety Approved'];
    const latestChange = changeEvents.find((ev) => ev.poaKey === poaKey);
    return !latestChange || nonFinalStates.includes(latestChange.status);
  };

  const POAHeaders = poaVersions.map((POA: any) => {
    const anotherVersionPending = lockPoa(POA.poaKey);
    const cypressTag = `audit-history-link-${POA.poaId}-${POA.version}`;
    return (
      <>
        <Typography className={classes.suretyName}>
          {getSuretyNameByIdentity(POA.suretyCompany)}
        </Typography>
        <Divider />
        <Typography
          data-cy={cypressTag}
          className={!anotherVersionPending ? classes.poaLink : classes.poaText}
          onClick={() => openAuditHistory(POA.poaKey)}
        >
          {POA.poaId}-{POA.version}{' '}
          {anotherVersionPending ? (
            <LockIcon data-cy="locked-icon" sx={{ fontSize: '1em' }} />
          ) : (
            <></>
          )}
          <br></br>
          {POA.poaKey}
        </Typography>
      </>
    );
  });

  const knownHeaders: any = ['First', 'Middle', 'Last', 'Suffix', 'Email'];
  const shortKnownHeaders: any = ['Middle', 'Suffix']; // for setting widths
  const longKnownHeaders: any = ['First', 'Last', 'Email'];

  const columnHeaders = knownHeaders.concat(POAHeaders).concat('');

  const handleAddButton = () => {
    setAifs((aifs) => {
      const id = -Math.floor(Math.random() * 10000);
      return [
        ...aifs,
        {
          original: {
            id,
            firstName: '',
            middleName: '',
            lastName: '',
            suffix: '',
            email: '',
            poas: poaVersions.map((poa) => ({
              id: poa.id,
              poaId: poa.poaId,
              poaKey: poa.poaKey,
              version: poa.version,
              suretyCompany: poa.suretyCompany,
              isAif: false
            }))
          },
          current: {
            id,
            firstName: '',
            middleName: '',
            lastName: '',
            suffix: '',
            email: '',
            poas: poaVersions.map((poa) => ({
              id: poa.id,
              poaId: poa.poaId,
              poaKey: poa.poaKey,
              version: poa.version,
              suretyCompany: poa.suretyCompany,
              isAif: false
            }))
          }
        } as AifMaintenance
      ];
    });
  };

  const aifInformationChanged = (original: Aif, updated: Aif) => {
    const filterInfo = (aif: Aif) => {
      if (!aif) {
        return aif;
      }
      const { firstName, middleName, lastName, suffix, email } = aif;
      return {
        firstName,
        middleName,
        lastName,
        suffix,
        email
      };
    };
    return !isEqual(filterInfo(original), filterInfo(updated));
  };

  interface poaInfo {
    suretyCompany: string;
    aifChanges: any[];
  }

  const saveAll = async () => {
    setSubmittingChanges(true);
    const mapAif = (details: AifMaintenanceDetails) => {
      const { id, poas, ...extra } = details;
      return extra;
    };
    const poaChanges: Record<string, poaInfo> = {};
    for (const aif of aifs) {
      const updated =
        aif.original.firstName !== aif.current.firstName ||
        aif.original.middleName !== aif.current.middleName ||
        aif.original.lastName !== aif.current.lastName ||
        aif.original.suffix !== aif.current.suffix ||
        aif.original.email !== aif.current.email;
      if (aif.current.id < 0) {
        await createNewAif(mapAif(aif.current));
      }
      for (let i = 0; i < aif.current.poas.length; i++) {
        const wasActive = aif.original.poas[i].isAif;
        const isActive = aif.current.poas[i].isAif;
        const added = isActive && !wasActive;
        const removed = !isActive && wasActive;
        let aifChange: any;
        if (added) {
          aifChange = {
            type: 'add',
            currentState: mapAif(aif.current)
          };
        } else if (removed) {
          aifChange = {
            type: 'remove',
            oldState: mapAif(aif.original)
          };
        } else if (updated && isActive) {
          aifChange = {
            type: 'update',
            oldState: mapAif(aif.original),
            currentState: mapAif(aif.current)
          };
        }
        if (aifChange !== undefined) {
          const poaKey = aif.current.poas[i].poaKey;
          if (poaKey in poaChanges) {
            poaChanges[poaKey].aifChanges.push(aifChange);
          } else {
            poaChanges[poaKey] = {
              suretyCompany: aif.current.poas[i].suretyCompany,
              aifChanges: [aifChange]
            };
          }
        }
      }
    }
    for (const poaKey in poaChanges) {
      const info = poaChanges[poaKey];
      await createPoaChangeEvent({
        poaKey,
        suretyIdentity: info.suretyCompany,
        aifChanges: info.aifChanges
      });
    }
    setTimeout(() => {
      setRefresh('poachangecreated');
      setSubmittingChanges(false);
    }, 2000);
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" padding="normal" stickyHeader>
          <TableHead>
            <TableRow>
              {columnHeaders?.map((c: any, i: any) => {
                return (
                  <TableCell
                    key={i}
                    size={'small'}
                    sx={{
                      verticalAlign: !knownHeaders.includes(c) ? 'top' : '',
                      fontSize: '13px',
                      fontWeight: '500',
                      textAlign: 'left',
                      maxWidth: knownHeaders.includes(c) ? 'none' : '80px',
                      minWidth: shortKnownHeaders.includes(c)
                        ? '50px'
                        : longKnownHeaders.includes(c)
                        ? '100px'
                        : 'none',
                      overflowWrap: 'break-word'
                    }}
                    color="textSecondary"
                  >
                    {c}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {aifs?.map((aif) => (
              <AifTableRow
                data-cy="aif-table-row"
                key={aif.current.id}
                aif={aif.current}
                setAif={(newAif) => {
                  const aifsCopy = [...aifs];
                  const aif1 = aifsCopy.find((a) => a.current.id === newAif.id);
                  if (aif1) aif1.current = newAif;
                  setAifs(aifsCopy);
                }}
                lockPoa={lockPoa}
                handleDeleteButton={
                  aif.current.id < 0
                    ? () => {
                        setAifs((aifs) =>
                          aifs.filter((a) => a.current.id !== aif.current.id)
                        );
                      }
                    : undefined
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Grid item container justifyContent="center">
        <Button
          data-cy="add-new-aif-btn"
          variant="outlined"
          disabled={submittingChanges}
          sx={{
            borderColor: '#D1855B',
            textTransform: 'none',
            color: '#D1855B'
          }}
          onClick={() => handleAddButton()}
        >
          + Add new AIF
        </Button>
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button
          sx={{
            borderColor: '#D1855B',
            backgroundColor: '#D1855B',
            textTransform: 'none',
            color: 'white'
          }}
          disabled={submittingChanges}
          color="primary"
          variant="contained"
          onClick={() => saveAll()}
          data-cy="submit-btn"
        >
          Submit Updates
        </Button>
      </Grid>
    </>
  );
}

import { url } from '../App';
import { PoaVersion } from './PoaVersionService';
import { handleAPIError } from './Utils';

export interface AifDetails {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  email: string;
}

export interface Aif extends AifDetails {
  createdAt?: string;
  updatedAt?: string;
  hash: string;
  poaVersions: PoaVersion[];
}

export interface AifMaintenanceDetails extends AifDetails {
  poas: AifPOA[];
}

export interface AifMaintenance {
  original: AifMaintenanceDetails;
  current: AifMaintenanceDetails;
}

export type AifPOA = {
  id: number;
  poaKey: string;
  poaId: string;
  version: number;
  suretyCompany: string;
  isAif?: boolean;
};

export async function getAgencyAIFs() {
  try {
    const response = await fetch(`${url}/aifs`);
    return await response.json();
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getSuretyAIFs() {
  try {
    const response = await fetch(`${url}/aifs`);
    return await response.json();
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAifByHash(hash: string) {
  try {
    const response = await fetch(`${url}/aifs/hash/${hash}`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return null;
  }
}

export async function createNewAif(body: any) {
  try {
    const response = await fetch(`${url}/aifs`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

import { useContext, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import PageHeader from '../components/PageHeader';
import AgencyManageAIFsTable from '../components/AgencyManageAIFsTable';
import {
  Aif,
  AifDetails,
  AifMaintenance,
  AifMaintenanceDetails,
  AifPOA
} from '../services/EmployeeService';
import {
  getAgencyChangeEvents,
  getAIFs,
  getFireflyOrgs
} from '../services/AgencyService';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { IOrg } from '../data/interfaces';
import { AppContext } from '../contexts/AppContext';
import { getPoaVersions, PoaVersion } from '../services/PoaVersionService';

const useStyles = makeStyles({
  tableContainer: {
    overflowX: 'auto',
    fontFamily: 'Spline Sans !important',
    backgroundColor: '#fff',
    marginBottom: '32px',
    width: '98% !important',
    padding: '1%'
  },
  poaText: {
    fontSize: '12px !important',
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  poaLink: {
    fontSize: '12px !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#D1855B',
    textAlign: 'center'
  },
  searchItem: {
    padding: '5px 16px 0 0'
  },
  suretySelect: {
    width: '175px',
    height: '35px'
  }
});

export default function AgencyManageAIFs() {
  const [data, setData] = useState<AifMaintenance[]>([]);
  const [poaVersions, setPoaVersions] = useState<PoaVersion[]>([]);
  const [refresh, setRefresh] = useState('');
  const [changeEvents, setChangeEvents] = useState([]);
  const [agencyCodeValue, setAgencyCodeValue] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [suretyCompany, setSuretyCompany] = useState('');
  const classes = useStyles();
  const [lastUpdate, setLastUpdate] = useState(0);
  const { lastEvent } = useContext(AppContext);

  useEffect(() => {
    const fetchChangeEvents = async () => {
      const result = await getAgencyChangeEvents();
      setChangeEvents(result);
    };
    const fetchData = async () => {
      const aifs: Aif[] = await getAIFs();
      const poas: PoaVersion[] = await getPoaVersions(
        agencyCodeValue,
        suretyCompany
      );
      const mapAif = (aif: Aif) => {
        return {
          id: aif.id,
          firstName: aif.firstName,
          middleName: aif.middleName,
          lastName: aif.lastName,
          suffix: aif.suffix,
          email: aif.email
        } as AifDetails;
      };
      const mapPoas = (aif: Aif) => {
        return poas.map((poa) => {
          return {
            id: poa.id,
            poaId: poa.poaId,
            poaKey: poa.poaKey,
            version: poa.version,
            suretyCompany: poa.suretyCompany,
            isAif: aif.poaVersions.find((v) => v.id === poa.id) !== undefined
          } as AifPOA;
        });
      };
      setPoaVersions(poas);
      setData(
        aifs.map((aif) => {
          return {
            original: { ...mapAif(aif), poas: mapPoas(aif) },
            current: { ...mapAif(aif), poas: mapPoas(aif) }
          } as AifMaintenance;
        })
      );
    };
    fetchChangeEvents();
    fetchData();
  }, [refresh, lastUpdate]);

  useEffect(() => {
    if (lastEvent && lastEvent.event === 'poa-updated') {
      setLastUpdate(new Date().getTime());
    }
  }, [lastEvent]);

  useEffect(() => {
    const fetchOrgs = async () => {
      const result = await getFireflyOrgs();
      setOrgs(result);
    };
    fetchOrgs();
  }, []);

  const handleSuretyChange = (event: any) => {
    setSuretyCompany(event.target.value);
  };

  const suretySelection = [
    <MenuItem key={''} value="">
      None
    </MenuItem>
  ];
  orgs.map((c: IOrg) => {
    if (
      // if its a surety
      c.name === 'org_0' || //  TODO: first 2 conditions are temporary until FF updates
      c.name === 'org_3' ||
      c.name.includes('s1') || // test env names
      c.name.includes('s2') ||
      c.name === 'surety1' || // dev env names
      c.name === 'surety2'
    ) {
      return suretySelection.push(
        <MenuItem key={c.identity} value={c.identity}>
          {c.name}
        </MenuItem>
      );
    }
  });

  return (
    <Container maxWidth={false}>
      <PageHeader
        header="Manage AIFs"
        subtitle="Add, remove, and edit attorneys in fact that are associated with powers of attorney documents."
      />
      <TableContainer className={classes.tableContainer}>
        <Table size="small" padding="normal">
          <TableHead>
            <TableRow sx={{ fontWeight: 'bold' }}>Search</TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ fontWeight: 'bold' }}>
              <Grid sx={{ marginTop: '16px', display: 'flex' }}>
                <Grid item xs={2}>
                  <InputLabel
                    className={classes.searchItem}
                    id="agency-code-label"
                  >
                    Agency Code
                  </InputLabel>
                  <TextField
                    data-cy="agency-code-filter-input"
                    onChange={(e) => {
                      setAgencyCodeValue(e.target.value);
                    }}
                    size="small"
                    disabled={suretyCompany !== ''}
                    value={agencyCodeValue ?? ''}
                    InputProps={{
                      sx: { fontSize: '12px', fontFamily: 'Spline Sans' }
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel
                    className={classes.searchItem}
                    id="surety-company-label"
                  >
                    or Surety Company
                  </InputLabel>
                  <Select
                    data-cy="surety-filter-input"
                    id="suretyCompanySelection"
                    value={suretyCompany}
                    defaultValue=""
                    disabled={agencyCodeValue !== ''}
                    size="small"
                    label="Surety Company"
                    onChange={handleSuretyChange}
                    className={classes.suretySelect}
                  >
                    {suretySelection}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    data-cy="filter-btn"
                    sx={{
                      borderColor: '#D1855B',
                      backgroundColor: '#D1855B',
                      textTransform: 'none',
                      color: 'white',
                      marginTop: '22px'
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setRefresh(agencyCodeValue.concat(suretyCompany));
                    }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <AgencyManageAIFsTable
        data={data}
        setData={setData}
        poaVersions={poaVersions}
        setRefresh={setRefresh}
        changeEvents={changeEvents}
      />
    </Container>
  );
}

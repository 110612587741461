import './App.css';
import { CssBaseline } from '@mui/material';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Box from '@mui/material/Box';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SuretyProcessPOAs from './pages/SuretyProcessPOAs';
import AuditHistory from './pages/AuditHistory';
import ObligeeView from './pages/ObligeeView';
import AgencyManageAIFs from './pages/AgencyManageAIFs';
import SuretyOwnedPOAs from './pages/SuretyOwnedPOAs';
import { AppType } from './data/enums';
import Header from './components/Header';
import { CircularProgress } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import AgencyAIFHistory from './pages/AgencyAIFHistory';
import AgencyProcessPOAs from './pages/AgencyProcessPOAs';
import ReconnectingWebsocket from 'reconnecting-websocket';
import { IWebsocketEvent } from './data/interfaces';
import { AppContext } from './contexts/AppContext';

export const appMode = process.env['REACT_APP_TYPE'] ?? 'agency';

let host = `${window.location.protocol}//${window.location.hostname}/api/v1`;
let url = host;
if (host.includes('localhost')) {
  if (appMode === 'surety') {
    url = 'http://localhost:3000/api/v1';
  } else if (appMode === 'agency') {
    url = 'http://localhost:3001/api/v1';
  } else if (appMode === 'obligee') {
    url = 'http://localhost:3002/api/v1';
  }
}

let uiBaseUrl = window.location.origin;

export { url, uiBaseUrl };
const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3498DB'
    },
    background: {
      default: '#F6F5EE'
    }
  },
  typography: {
    fontFamily: `${[
      'Spline Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')} !important`
  },
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: '#fff',
        fontSize: '13px !important',
        fontFamily: 'Spline Sans'
      }
    },
    MuiTableCell: {
      root: { fontFamily: 'Spline Sans' }
    },
    MuiTypography: {
      root: {
        fontFamily: 'Spline Sans'
      },
      h4: {
        fontWeight: 700
      }
    },
    MuiButton: {
      root: {
        background: '#fff',
        backgroundColor: '#fff',
        color: '#D1855B',
        border: '#D1855B 1px solid !important',
        textTransform: 'none'
      },
      outlined: {
        borderColor: '#D1855B !important',
        color: '#D1855B'
      },
      contained: {
        textTransform: 'none',
        color: '#D1855B',
        border: '#D1855B 1px solid !important',
        backgroundColor: '#fff'
      },
      containedPrimary: {
        backgroundColor: '#D1855B !important'
      },
      label: {
        textTransform: 'none'
      }
    }
  }
});

function App() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [lastEvent, setLastEvent] = useState<IWebsocketEvent>();
  const ws = useRef<ReconnectingWebsocket | null>(null);

  useEffect(() => {
    async function checkAuth() {
      setLoading(true);
      try {
        const res = await window.fetch('/api/v1/self', {
          method: 'GET'
        });

        if (res.status === 401 || res.status === 403) {
          window.location.replace(`${uiBaseUrl}/api/v1/login`);
          return;
        }

        let self = await res.json();
        setUsername(self.user.username);

        ws.current = new ReconnectingWebsocket(
          `${protocol}://${window.location.hostname}:${window.location.port}/api/ws`
        );
        ws.current.onmessage = (message: MessageEvent) => {
          const event: IWebsocketEvent = JSON.parse(message.data);
          console.log(`setLastEvent: ${JSON.stringify(event, null, 1)}`);
          setLastEvent(event);
        };
      } catch (err) {
        console.log(err);
        console.log('error fetching user info');
        if (!window.location.hostname.includes('localhost')) {
          window.location.replace(`${uiBaseUrl}/api/v1/login`);
        }
      }
      setLoading(false);
    }
    checkAuth();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <AppContext.Provider value={{ lastEvent, setLastEvent }}>
          <BrowserRouter>
            <Header user={username} />
            <Box
              sx={{ margin: '32px 32px 64px 32px', backgroundColor: '#f6f5ee' }}
            >
              <Routes>
                {appMode === AppType.surety ? (
                  <>
                    <Route path="/" element={<SuretyProcessPOAs />} />
                    <Route
                      path="/suretyownedpoas"
                      element={<SuretyOwnedPOAs />}
                    />
                    <Route
                      path="audithistory/:poaKey"
                      element={<AuditHistory />}
                    />
                  </>
                ) : appMode === AppType.agency ? (
                  <>
                    <Route path="/" element={<AgencyManageAIFs />} />
                    <Route
                      path="/agencyprocesspoas"
                      element={<AgencyProcessPOAs />}
                    />
                    <Route
                      path="audithistory/:poaKey"
                      element={<AuditHistory />}
                    />
                    <Route path="/aifhistory" element={<AgencyAIFHistory />} />
                  </>
                ) : appMode === AppType.obligee ? (
                  <>
                    <Route path="/" element={<ObligeeView />} />
                  </>
                ) : (
                  <>
                    <Route
                      path="/"
                      element={
                        <div>
                          Uh oh, no environment variable was configured.
                        </div>
                      }
                    />
                  </>
                )}
              </Routes>
            </Box>
          </BrowserRouter>
        </AppContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;

import { uiBaseUrl } from '../App';
import { aifInfo, IAllAifInfo, IOrg } from '../data/interfaces';
import { getFireflyOrgs } from './AgencyService';

const sha256 = require('js-sha256');

export const hashAif = (aifState: any) => {
  const hash = sha256.create();
  hash.update(JSON.stringify(aifState));
  const hashResult = hash.hex();
  return hashResult;
};

export function validateEmail(email: string) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// make pretty string out of AIF info
export const joinNames = (aif: aifInfo | IAllAifInfo) => {
  return `${aif.firstName} ${aif.middleName ?? ''} ${aif.lastName} ${
    aif.suffix ?? ''
  } (${aif.email})`;
};

export const handleAPIError = (error: any) => {
  console.log(error);
  if (error.statusCode === '403') {
    window.location.replace(`${uiBaseUrl}/api/v1/login`);
    return;
  }
};

export const openAuditHistory = (poaKey: string) => {
  window.open(`${uiBaseUrl}/audithistory/${poaKey}`);
}

export const getSuretyNameByIdentity = (identity: string, orgs: IOrg[]) => {
  for (let org of orgs) {
    if (org.identity === identity) {
      return org.name;
    }
  }
  return null;
};
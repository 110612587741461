import { url } from '../App';
import { IBondData } from '../data/interfaces';
import { handleAPIError } from './Utils';

export async function obligeeCheck(
  bondData: IBondData,
  suretyIdentity: string
) {
  const response = await fetch(`${url}/poaversion/obligeecheck`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      poaId: bondData.poaId,
      suretyIdentity: suretyIdentity,
      agencyCode: bondData.agencyCode,
      firstName: bondData.firstName,
      middleName: bondData.middleName,
      lastName: bondData.lastName,
      suffix: bondData.suffix,
      email: bondData.email,
      certificationDate: bondData.certificationDate
    })
  });
  return await response.json();
}

export async function obligeeGetFFMembers() {
  try {
    const response = await fetch(`${url}/firefly/orgs`);
    return await response.json();
  } catch (error) {
    handleAPIError(error);
    return [];
  }
}

import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { makeStyles} from '@material-ui/core/styles';
import PageHeader from '../components/PageHeader';
import ObligeeVerification from '../components/ObligeeVerification';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const HEADER_HEIGHT = 80;

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 200,
    height: HEADER_HEIGHT
  }
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ObligeeView() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#F6F5EE' }}>
      <Container maxWidth="lg">
        <PageHeader
          header="Obligee Verification"
          subtitle="Use this screen to check if power of attorney documents are valid or not."
        />
        <ObligeeVerification />
      </Container>
    </Box>
  );
}

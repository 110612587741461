import { useState, forwardRef, useEffect, useContext } from 'react';
import Container from '@mui/material/Container';
import { Button, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { Icons } from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SuretyProcessChange from '../components/SuretyProcessChange';
import { IChangeEvent } from '../data/interfaces';
import { TransStatus } from '../data/enums';
import { getChangeEvents } from '../services/SuretyService';
import { SuretyUploadHeader } from '../components/SuretyUploadHeader';
import { getAgencies } from '../services/AgencyService';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { ArrowDownward } from '@material-ui/icons';
import { openAuditHistory } from '../services/Utils';
import { AppContext } from '../contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { rootShouldForwardProp } from '@mui/material/styles/styled';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  uploadHeader: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between'
  },
  uploadButton: {},
  success: {
    color: 'green'
  },
  pending: {},
  failure: {},
  poaLink: {
    fontSize: '13px !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#D1855B'
  },
  poaNoLink: {
    fontSize: '13px !important'
  }
}));

export default function SuretyProcessPOAs() {
  const sortOrder = [
    'Pending',
    'Surety Approved',
    'Declined by Surety',
    'Declined by Agency',
    'Agency Accepted'
  ];

  const [processRequestModal, setProcessRequestModal] = useState(false);
  const [currentPOA, setCurrentPOA] = useState<IChangeEvent | null>(null);
  const [allAgencies, setAllAgencies] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IChangeEvent[]>([]);
  const { lastEvent } = useContext(AppContext);
  const [lastUpdate, setLastUpdate] = useState(0);
  const { t } = useTranslation();
  // We can remove this in favor of refreshing on websocket events, see lastEvent/lastUpdate.
  // Keeping it here so we don't have to refactor all places it's used at once
  const [refresh, setRefresh] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let res = await getAgencies();
      let identityMap: any = {};
      for (const agency of res) {
        for (const code of agency.agencyCodes) {
          identityMap[code] = agency.name;
        }
      }
      setAllAgencies(identityMap);
      setLoading(false);
    };
    fetchData();
  }, [lastUpdate]);

  useEffect(() => {
    if (lastEvent && lastEvent.event === 'poa-updated') {
      setLastUpdate(new Date().getTime());
    }
  }, [lastEvent]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let result = await getChangeEvents();
      result = result.map((d: any) => {
        return {
          ...d,
          updatedAt: new Date(d.updatedAt).toLocaleString(),
          agencyIdentity: allAgencies[d.agencyCode] ?? d.agencyIdentity
        };
      });
      result.sort((a: IChangeEvent, b: IChangeEvent) => {
        return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status);
      });
      setData(result);
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, [allAgencies]);

  const tableIcons: Icons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    ))
  };

  const handleProcessRequestClick = (
    poaId: string,
    poaKey: string,
    changeEventId: string,
    status: string,
    agencyIdentity: string,
    suretyIdentity: string,
    currentPoaVersion: number,
    aifChanges: any
  ) => {
    for (let aif of aifChanges) {
      // these need to be null, not empty strings
      if ((aif.middleName = '')) {
        aif.middleName = null;
      }
      if ((aif.suffix = '')) {
        aif.suffix = null;
      }
    }
    setCurrentPOA({
      poaId,
      poaKey,
      changeEventId,
      status,
      agencyIdentity,
      suretyIdentity,
      currentPoaVersion,
      aifChanges
    });
    setProcessRequestModal(true);
  };
  const classes = useStyles();

  const columns = [
    {
      title: 'Updated At',
      field: 'updatedAt'
    },
    { title: 'POA ID', field: 'poaId' },
    {
      title: 'POA Key',
      field: 'poaKey',
      render: (rowData: IChangeEvent) => {
        return (
          <Typography
            data-cy="audit-history-link"
            className={classes.poaLink}
            onClick={() => openAuditHistory(rowData.poaKey)}
          >
            {rowData.poaKey}
          </Typography>
        );
      }
    },
    { title: 'Agency', field: 'agencyIdentity' },
    { title: 'Agency Code', field: 'agencyCode' },
    {
      title: 'Transaction ID',
      field: 'changeEventId',
      render: (rowData: IChangeEvent) => {
        return (
          <Tooltip title={rowData.changeEventId}>
            <Typography
              data-cy="change-event-id-txt"
              variant={'body2'}
              onClick={() => {
                navigator.clipboard.writeText(rowData.changeEventId);
              }}
            >
              {rowData.changeEventId.substring(0, 12) + '...'}
              <CopyIcon
                sx={{
                  fontSize: '0.85rem !important',
                  cursor: 'pointer',
                  color: '#d1855b'
                }}
              ></CopyIcon>
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
      render: (rowData: IChangeEvent) => (
        <div data-cy="status-txt">
          {(rowData.status === TransStatus.declinedByAgency ||
            rowData.status === TransStatus.declinedBySurety) &&
          rowData.declineReason ? (
            <Tooltip title={rowData.declineReason}>
              <div
                style={{
                  color: 'red'
                }}
              >
                {rowData.status}
              </div>
            </Tooltip>
          ) : (
            <div
              style={{
                color:
                  rowData.status === TransStatus.approved ||
                  rowData.status === TransStatus.accepted
                    ? 'green'
                    : rowData.status === TransStatus.pending
                    ? 'orange'
                    : 'red'
              }}
            >
              {rowData.status}
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Actions',
      filtering: false,
      render: (rowData: IChangeEvent) => {
        if (
          rowData.status === TransStatus.pending ||
          rowData.status === TransStatus.declinedByAgency
        ) {
          return (
            <Button
              data-cy="process-request-inline-btn"
              size="small"
              variant="outlined"
              onClick={() =>
                handleProcessRequestClick(
                  rowData.poaId,
                  rowData.poaKey,
                  rowData.changeEventId,
                  rowData.status,
                  rowData.agencyIdentity,
                  rowData.suretyIdentity,
                  rowData.currentPoaVersion,
                  rowData.aifChanges
                )
              }
            >
              Process Request
            </Button>
          );
        }
        return null;
      }
    }
  ];

  function renderProcessModal() {
    return (
      <SuretyProcessChange
        modalOpen={processRequestModal}
        setModalOpen={setProcessRequestModal}
        currentPOA={currentPOA}
        {...{ setRefresh }}
      />
    );
  }

  return (
    <Container maxWidth="xl">
      <SuretyUploadHeader
        header={t('processPOAs')}
        subtitle={
          'Upload, approve, and decline agency powers-of-attorney documents.'
        }
        suretyPOAOnly={false}
        {...{ setRefresh }}
      />
      {renderProcessModal()}
      {!loading && (
        <MaterialTable
          columns={columns}
          data={data}
          options={{
            thirdSortClick: false,
            filtering: true,
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10, 25],
            showTitle: false,
            sorting: true,
            paginationType: 'normal',
            showFirstLastPageButtons: true,
            paginationPosition: 'bottom',
            search: false,
            toolbar: false,
            toolbarButtonAlignment: 'left',
            padding: 'dense',
            rowStyle: {
              overflowWrap: 'break-word'
            }
          }}
          icons={tableIcons}
        />
      )}
    </Container>
  );
}

import Box from '@mui/material/Box';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Typography } from '@mui/material';
import { ReactComponent as AgencyLogo } from '../public/agency-logo.svg';
import { ReactComponent as ObligeeLogo } from '../public/obligee-logo.svg';
import { ReactComponent as SuretyLogo } from '../public/surety-logo.svg';

import { makeStyles } from '@material-ui/core/styles';
import { appMode } from '../App';
import { AppType } from '../data/enums';
import LogoutIcon from '@mui/icons-material/Logout';

const HEADER_HEIGHT = 80;

const useStyles = makeStyles((theme) => ({
  link: {
    height: HEADER_HEIGHT,
    fontFamily: 'Spline Sans, sans-serif',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: 700,
    alignContent: 'center',
    color: 'white',
    lineHeight: '80px'
  },
  menuItem: {
    marginRight: '30px !important'
  },
  selected: {
    marginRight: '30px !important',
    width: 'auto',
    color: '#F6F5EE',
    borderBottom:
      appMode === AppType.surety
        ? '3px solid #d1855b'
        : appMode === AppType.agency
        ? '3px solid #99CBA7'
        : '3px solid #D6D3C0'
  },
  logoutIcon: {
    color: '#fff',
    cursor: 'pointer',
    fontSize: '1rem !important'
  },
  logoutText: {
    color: '#fff',
    fontSize: '1rem !important',
    display: 'inline',
    paddingRight: '10px',
    fontFamily: 'Spline Sans !important'
  }
}));

export default function Header(props: { user: string }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { user } = props;

  return (
    <Box sx={{ width: '100%', backgroundColor: '#F6F5EE' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'black',
          display: 'flex',
          height: HEADER_HEIGHT
        }}
      >
        <Icon style={{ padding: 20, width: 250, height: 80 }}>
          {appMode === AppType.surety ? (
            <SuretyLogo />
          ) : appMode === AppType.agency ? (
            <AgencyLogo />
          ) : (
            <ObligeeLogo />
          )}
        </Icon>
        {appMode === AppType.surety ? (
          <>
            {' '}
            <Link className={classes.link} data-cy="process-poas-nav" to="/">
              <Box
                className={
                  pathname === '/' ? classes.selected : classes.menuItem
                }
              >
                Process POAs
              </Box>
            </Link>
            <Link
              className={classes.link}
              data-cy="surety-owned-nav"
              to="/suretyownedpoas"
            >
              <Box
                className={
                  pathname === '/suretyownedpoas'
                    ? classes.selected
                    : classes.menuItem
                }
              >
                Surety-owned POAs
              </Box>
            </Link>
          </>
        ) : appMode === AppType.agency ? (
          <>
            <Link data-cy="manage-aifs-nav" className={classes.link} to="/">
              <Box
                className={
                  pathname === '/' ? classes.selected : classes.menuItem
                }
              >
                Manage AIFs
              </Box>
            </Link>
            <Link
              data-cy="process-poas-nav"
              className={classes.link}
              to="/agencyprocesspoas"
            >
              <Box
                className={
                  pathname === '/agencyprocesspoas'
                    ? classes.selected
                    : classes.menuItem
                }
              >
                Process POAs
              </Box>
            </Link>
            <Link
              data-cy="aif-history-nav"
              className={classes.link}
              to="/aifhistory"
            >
              <Box
                className={
                  pathname === '/aifhistory'
                    ? classes.selected
                    : classes.menuItem
                }
              >
                AIF History
              </Box>
            </Link>
          </>
        ) : appMode === AppType.obligee ? (
          <>
            <Link data-cy="obligee-nav" className={classes.link} to="/">
              <Box
                className={
                  pathname === '/' ? classes.selected : classes.menuItem
                }
              >
                Obligee Verification
              </Box>
            </Link>
          </>
        ) : (
          <></>
        )}
        <Box
          sx={{
            right: '30px',
            float: 'right',
            position: 'absolute',
            lineHeight: '80px'
          }}
        >
          <Typography
            variant="h6"
            color="white"
            data-cy="user-txt"
            className={classes.logoutText}
          >
            {user ?? ''}
          </Typography>
          <LogoutIcon
            data-cy="logout-nav"
            className={classes.logoutIcon}
            onClick={() => (window.location.href = '/api/v1/logout')}
          />
        </Box>
      </Box>
    </Box>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageHeader from '../components/PageHeader';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { getAgencyAIFs } from '../services/EmployeeService';
import { IAllAifInfo, IOrg } from '../data/interfaces';
import { makeStyles } from '@material-ui/core/styles';
import { uiBaseUrl } from '../App';
import { getSuretyNameByIdentity, openAuditHistory } from '../services/Utils';
import { getFireflyOrgs } from '../services/AgencyService';

const useStyles = makeStyles((theme) => ({
  clickable: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  tableContainer: {
    overflowX: 'auto',
    fontFamily: 'Spline Sans !important',
    backgroundColor: '#fff',
    marginBottom: '32px',
    width: '98% !important',
    padding: '1%'
  }
}));

function Row(props: { row: IAllAifInfo }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [orgs, setOrgs] = useState<IOrg[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const resOrgs = await getFireflyOrgs();
      setOrgs(resOrgs);
    };
    fetchData();
  }, []);

  const classes = useStyles();

  const sortPoaVersion = (a: any, b: any) => {
    if (a.poaKey > b.poaKey) return -1;
    if (a.poaKey < b.poaKey) return 1;
    const aInt = parseInt(a.version);
    const bInt = parseInt(b.version);
    if (isNaN(aInt) || isNaN(bInt)) {
      if (a.version > b.version) return -1;
      if (a.version < b.version) return 1;
      return 0;
    }
    if (aInt > bInt) return -1;
    if (aInt < bInt) return 1;
    return 0;
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, fontSize: '13px' }}>
        <TableCell>
          {row.poaVersions.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              disabled={row.poaVersions.length === 0}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right">{row.firstName}</TableCell>
        <TableCell align="right">{row.middleName}</TableCell>
        <TableCell align="right">{row.lastName}</TableCell>
        <TableCell align="right">{row.suffix}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                POA History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Surety</TableCell>
                    <TableCell>POA ID</TableCell>
                    <TableCell>POA Key</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.poaVersions.sort(sortPoaVersion).map((poa: any) => (
                    <TableRow key={poa.legalDocumentId}>
                      <TableCell>
                        {getSuretyNameByIdentity(poa.suretyCompany, orgs)}
                      </TableCell>

                      <TableCell>{poa.poaId}</TableCell>
                      <TableCell
                        data-cy="audit-history-link"
                        sx={{ color: '#D1855B' }}
                        className={classes.clickable}
                        onClick={() => openAuditHistory(poa.poaKey)}
                      >
                        {poa.poaKey}
                      </TableCell>
                      <TableCell>{poa.version}</TableCell>
                      <TableCell>{poa.status}</TableCell>
                      <TableCell>{poa.startDate}</TableCell>
                      <TableCell>{poa.endDate || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function AgencyAIFHistory() {
  const [data, setData] = useState<IAllAifInfo[]>([]);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const result: IAllAifInfo[] = await getAgencyAIFs();
      setData(result);
    };
    fetchData();
  }, []);

  return (
    <Container maxWidth={false}>
      <PageHeader
        header="AIF History"
        subtitle="See Attorneys-in-Fact and all their associated POAs"
      />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ fontWeight: 'bold !important' }}>
              <TableCell />
              <TableCell align="right">First Name</TableCell>
              <TableCell align="right">Middle Name</TableCell>
              <TableCell align="right">Last Name</TableCell>
              <TableCell align="right">Suffix</TableCell>
              <TableCell align="right">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.hash} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

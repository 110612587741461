import { useContext, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import PageHeader from '../components/PageHeader';
import {
  downloadFile,
  getChangeEventsByPoaKey,
  getFireflyOrgs
} from '../services/AgencyService';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  aifChanges,
  IAllAifInfo,
  IChangeEvent,
  IOrg,
  IPOAVersion
} from '../data/interfaces';
import { makeStyles } from '@material-ui/core';
import { getSuretyNameByIdentity, joinNames } from '../services/Utils';
import { getPoaVersionsByKey } from '../services/PoaVersionService';
import { AppContext } from '../contexts/AppContext';
import { TransStatus } from '../data/enums';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const useStyles = makeStyles((theme) => ({
  gridTop: {
    paddingBottom: '10px'
  },
  gridMain: {
    paddingTop: '20px'
  },
  aifList: {
    paddingTop: '15px'
  },
  card: {
    marginBottom: '30px'
  },
  downloadBtn: {
    float: 'right'
  },
  arrowIcon: {
    top: '5px',
    position: 'relative',
    paddingLeft: '3px',
    paddingRight: '3px'
  }
}));

export default function AuditHistory() {
  const classes = useStyles();
  let params = useParams();
  let poaKey = params.poaKey;
  const [poaVersions, setPoaVersions] = useState<IPOAVersion[]>([]);
  const [poaChangeEvents, setPoaChangeEvents] = useState<IChangeEvent[]>([]);
  const { lastEvent } = useContext(AppContext);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [orgs, setOrgs] = useState<IOrg[]>([]);

  useEffect(() => {
    if (
      lastEvent &&
      lastEvent.event === 'poa-updated' &&
      lastEvent.data.key === poaKey
    ) {
      setLastUpdate(new Date().getTime());
    }
  }, [lastEvent]);

  useEffect(() => {
    const fetchData = async () => {
      const resVersions = await getPoaVersionsByKey(poaKey);
      setPoaVersions(resVersions);
      const resEvents = await getChangeEventsByPoaKey(poaKey);
      setPoaChangeEvents(resEvents);
      const resOrgs = await getFireflyOrgs();
      setOrgs(resOrgs);
    };
    fetchData();
  }, [lastUpdate]);

  const renderAIFs = (attorneysInFact: IAllAifInfo[]) => {
    let AIFList = [];
    for (let aif of attorneysInFact) {
      AIFList.push(<Typography variant="body2">{joinNames(aif)}</Typography>);
    }
    return AIFList;
  };

  const renderAIFChanges = (aifChanges: aifChanges[]) => {
    let changeList = [];
    for (let change of aifChanges) {
      if (change.type === 'update') {
        changeList.push(
          <div key={Math.random()}>
            <Typography
              data-cy="updated-aif-txt"
              display="inline"
              variant="body2"
            >
              UPDATE:{' '}
            </Typography>
            <Typography color="red" display="inline" variant="body2">
              {joinNames(change.oldState)}
            </Typography>
            <ArrowRightAltIcon
              display="inline"
              fontSize="small"
              className={classes.arrowIcon}
            />
            <Typography color="green" display="inline" variant="body2">
              {joinNames(change.currentState)}
            </Typography>
          </div>
        );
      } else if (change.type === 'add') {
        changeList.push(
          <div key={Math.random()}>
            <Typography
              data-cy="added-aif-txt"
              display="inline"
              variant="body2"
            >
              ADD:{' '}
            </Typography>
            <Typography color="green" display="inline" variant="body2">
              {joinNames(change.currentState)}
            </Typography>
          </div>
        );
      } else if (change.type === 'remove') {
        changeList.push(
          <div key={Math.random()}>
            <Typography
              data-cy="removed-aif-txt"
              display="inline"
              variant="body2"
            >
              REMOVE:{' '}
            </Typography>
            <Typography color="red" display="inline" variant="body2">
              {joinNames(change.oldState)}
            </Typography>
          </div>
        );
      }
    }
    return changeList;
  };

  const renderLatestChangeRequestCards = () => {
    if (poaChangeEvents.length > 0) {
      let event = poaChangeEvents[0]; //latest change request
      if (
        event.status === TransStatus.declinedBySurety ||
        event.status === TransStatus.pending
      ) {
        // only show latest pending or surety declined
        return (
          <Card
            sx={{ minWidth: 275, maxWidth: 600 }}
            className={classes.card}
            data-cy="latest-change-card"
          >
            <CardContent>
              <Grid container className={classes.gridTop}>
                <Grid item xs={12}>
                  {event.status === TransStatus.pending && (
                    <Typography data-cy="change-req-date-txt" variant="h6">
                      Latest Change Requested on:{' '}
                      {event.createdAt &&
                        new Date(event.createdAt).toLocaleString()}
                    </Typography>
                  )}
                  {event.status === TransStatus.declinedBySurety && (
                    <Typography data-cy="surety-decline-date-txt" variant="h6">
                      Latest Change Declined by Surety on:{' '}
                      {event.updatedAt &&
                        new Date(event.updatedAt).toLocaleString()}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Typography variant="body2" className={classes.aifList}>
                AIF Changes Requested:
              </Typography>
              {renderAIFChanges(event.aifChanges || [])}
            </CardContent>
          </Card>
        );
      }
    }
  };

  const renderVersionCards = () => {
    let cardList: any = [];
    for (let version of poaVersions) {
      cardList.push(
        <Card sx={{ minWidth: 275, maxWidth: 600 }} className={classes.card}>
          <CardContent>
            <Grid container className={classes.gridTop}>
              <Grid item xs={6}>
                <Typography data-cy="accepted-on-date-txt" variant="h6">
                  Issued on: {version.startDate ?? 'N/A'}
                </Typography>
              </Grid>
              {version.legalDocumentId && (
                <Grid item xs={6}>
                  <Button
                    data-cy="download-file-btn"
                    size="small"
                    onClick={() =>
                      downloadFile(
                        version.legalDocumentId,
                        version.poaId + '-' + version.version
                      )
                    }
                    variant="outlined"
                    color="primary"
                    className={classes.downloadBtn}
                    sx={{
                      borderColor: '#D1855B',
                      textTransform: 'none',
                      color: '#D1855B'
                    }}
                  >
                    Download File
                  </Button>
                </Grid>
              )}
            </Grid>
            <Divider />
            <Grid container spacing={2} className={classes.gridMain}>
              {version.agencyCode && (
                <Grid item xs={6}>
                  <Typography data-cy="agency-code-txt" variant="body2">
                    Agency Code: {version.agencyCode}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography data-cy="poa-id-txt" variant="body2">
                  POA ID: {version.poaId}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography data-cy="version-txt" variant="body2">
                  Version: {version.version}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography data-cy="status-txt" variant="body2">
                  Status: {version.status}
                </Typography>
              </Grid>
              {version.endDate && (
                <Grid item xs={6}>
                  <Typography variant="body2">
                    End Date: {version.endDate}
                  </Typography>
                </Grid>
              )}
              {version.documentExpiryDate && (
                <Grid item xs={6}>
                  <Typography data-cy="expire-date-txt" variant="body2">
                    Expiration Date: {version.documentExpiryDate}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography data-cy="surety-txt" variant="body2">
                  Surety: {getSuretyNameByIdentity(version.suretyCompany, orgs)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography data-cy="uri-txt" variant="caption">
                  URI: {version.uri}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" className={classes.aifList}>
              AIFs:
            </Typography>
            {renderAIFs(version.attorneysInFact)}
          </CardContent>
        </Card>
      );
    }
    return cardList;
  };

  let subtitle = `View version history of POA key ${poaKey}`;
  return (
    <Container maxWidth={false}>
      <PageHeader header="Audit History" subtitle={subtitle} />
      {renderLatestChangeRequestCards()}
      {renderVersionCards()}
    </Container>
  );
}
function resEvents(resEvents: any) {
  throw new Error('Function not implemented.');
}
